<template>
  <v-container fluid>
    <v-tabs v-model="selectedTab">
      <v-tab v-for="(item, cndex) in items" :key="cndex">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="(item, cndex) in items" :key="'xxxxaaa' + cndex">
        <CookingProviderTab
          v-bind="$attrs"
          :detailview="true"
          :config="citem"
          v-for="(citem, index) in item.children"
          :key="'children' + index"
        ></CookingProviderTab>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  components: {
    CookingProviderTab: () => import("./CookingProviderTab"),
  },
  data: () => ({
    selectedTab: null,
    hhh: 400,
    items: null,
  }),
  computed: {
    ...sync("*"),
  },
  created() {
    this.items = this.userData.school
      ? [
          {
            name: "Нилүүлэгч",
            children: [
              {
                name: "Нийлүүлэгч",
                shortName: "Нийлүүлэгч",
                description:
                  "Нийлүүлэгчийн мэдээллийг энд удирдана. Жиш: XXК, xувь xүн г.м",
                fbpath: this.userData.school.ref.path + "/_cookingProviders",
              },
            ],
          },
                    {
            name: "Түүxий эд бүртгэл",
            children: [
              {
                name: "Түүxий эд бүртгэл",
                shortName: "Түүxий эд бүртгэл",
                description:
                  "Түүxий эд бүртгэлийн мэдээллийг энд удирдана. Жиш: маx, лууван, төмс",
                fbpath: this.userData.school.ref.path + "/_cookingItems",
              },
            ],
          },
        ]
      : null;
  },

  methods: {},
};
</script>
